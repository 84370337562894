var controller = new AbortController();

var formatSignal;
export async function format(code) {
  try {
    if (formatSignal) {
      formatSignal.abort();
    }
    const res = await fetch("/api/format", {
      signal: formatSignal,
      method: "post",
      body: code,
    });
    if (res.status != 200) {
      throw new Error(await res.text());
    }
    return await res.text();
  } finally {
    formatSignal = null;
  }
}

var buildSignal;

export async function build(code, progress) {
  try {
    if (buildSignal) {
      buildSignal.abort();
    }
    buildSignal = controller.signal;
    const res = await fetch("/api/run", {
      signal: buildSignal,
      method: "POST",
      body: code,
    });
    if (res.status != 200) {
      throw new Error(await res.text());
    }
    const td = new TextDecoder();
    const reader = res.body.getReader();
    const total = res.headers.get("X-Content-Length");

    let data = "";
    for (let cur = 0; ; ) {
      const { done, value } = await reader.read();
      if (done || !value) {
        break;
      }
      data += td.decode(value);
      cur += value.length;
      if (progress) progress({ cur: cur, total: total });
    }
    return data;
  } finally {
    buildSignal = null;
  }
}

var shareSignal;
export async function share(code) {
  try {
    if (shareSignal) {
      shareSignal.abort();
    }
    const res = await fetch("/api/share", {
      signal: shareSignal,
      method: "post",
      body: code,
    });
    if (res.status != 200) {
      throw new Error(await res.text());
    }
    return await res.text();
  } finally {
    shareSignal = null;
  }
}

export async function open(id) {
  const res = await fetch("/shares/" + id);
  if (res.status != 200) {
    throw new Error(await res.text());
  }
  return await res.text();
}
