<template>
  <div class="output">
    <iframe :srcdoc="code"></iframe>
  </div>
</template>
<script>
export default {
  props: ["code"],
};
</script>
<style>
.output {
  flex: 1;
  background: #1e1e1e;
  border: none;
  display: flex;
}
.output > * {
  border: none;
  flex: 1;
}
.views.dragging iframe,
.split.resizing iframe {
  pointer-events: none;
}
</style>
