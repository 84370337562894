<template>
  <div class="editor" :class="{ vim: vimmode }">
    <div ref="editor" class="editor__thing" />
    <div ref="status" class="editor__vimstatus" />
  </div>
</template>
<script>
import * as monaco from "monaco-editor";
import { VimMode, initVimMode } from "monaco-vim";
// import { setupLanguageClient } from "../services/langclient";

export default {
  props: ["code", "theme", "vimmode", "codeModel"],

  mounted() {
    this.initEditor();
  },
  beforeDestroy() {
    this.destroyEditor();
  },
  watch: {
    theme: {
      set(value) {
        this.editor.updateOptions({ theme: value });
      },
    },
    vimmode() {
      this.toggleVimMode(this.vimmode);
    },
    /*code() {
      const position = editor.getPosition();
      model.setValue(this.code);
      editor.setPosition(position);
    },*/
  },
  methods: {
    toggleVimMode(v) {
      if (v) {
        this.vimmodec = initVimMode(this.editor, this.$refs.status);
      } else {
        if (this.vimmodec) this.vimmodec.dispose();
      }
    },
    initEditor() {
      this.editor = monaco.editor.create(this.$refs.editor, {
        model: this.codeModel,
        automaticLayout: true,
        language: "go",
        glyphMargin: true,
        lightbulb: {
          enabled: true,
        },
        links: false,
        definitionLinkOpensInPeek: false,
      });
      this.toggleVimMode(this.vimmode);
      VimMode.Vim.defineEx("write", "w", () => {
        this.$emit("save");
      });
      // Add monaco Ctrl+S as well
      this.editor.updateOptions({ theme: this.theme });

      // this consumes server resources also only provides completions for gorge
      // Maybe send keywoard to somewhere else godoc or something?
      //
      // setupLanguageClient(monaco);
    },
    destroyEditor() {
      this.model.dispose();
      this.editor.dispose();
    },
  },
  data() {
    return {};
  },
};
</script>
<style>
.editor {
  position: relative;
  padding: 0;
  overflow: hidden;
  height: 100%;
}
.editor__thing {
  position: absolute;
  top: 0px;
  width: 100%;
  bottom: 0px;
}
.editor__vimstatus {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 0px;
}
.editor.vim .editor__thing {
  bottom: 20px;
}
.editor.vim .editor__vimstatus {
  height: 20px;
}
.editor.vim .editor__vimstatus input {
  border: none;
  background: #1c1c1c;
  color: #aaa;
  outline: none;
}
</style>

