var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("TopBar", {
        attrs: { vimmode: _vm.vimmode },
        on: { run: _vm.build, share: _vm.share, vimmode: _vm.updateVimMode },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "Layout",
            { attrs: { resize: true, edit: true, splits: _vm.tree } },
            [
              _c(
                "Pane",
                { attrs: { title: "editor" } },
                [
                  _c("Editor", {
                    ref: "editor",
                    attrs: {
                      codeModel: _vm.codeModel,
                      theme: "vs-dark",
                      vimmode: _vm.vimmode,
                    },
                    on: { save: _vm.save },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Pane",
                { attrs: { title: "output" } },
                [_c("Output", { attrs: { code: _vm.compiled } })],
                1
              ),
              _vm._v(" "),
              _c(
                "Pane",
                { attrs: { title: "log" } },
                [_c("TermLog", { ref: "log" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }