<template>
  <div class="top-bar">
    gorge playground:
    <button @click="$emit('run')">Run</button>
    <button @click="$emit('share')">Share</button>
    <label>
      <input class="top-bar__vimmode" type="checkbox" v-model="vim" />vimmode
    </label>
  </div>
</template>
<script>
export default {
  props: ["vimmode"],
  computed: {
    vim: {
      get() {
        return this.vimmode;
      },
      set(value) {
        this.$emit("vimmode", value);
      },
    },
  },
};
</script>
<style>
.top-bar {
  font-family: monospace;
  background: #222;
  color: white;
  padding: 5px;
}
.top-bar__vimmode {
  vertical-align: middle;
}
</style>
