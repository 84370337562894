<template>
  <div class="term-log"></div>
</template>
<script>
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

export default {
  mounted() {
    const fitAddon = new FitAddon();
    this.term = new Terminal();
    this.term.open(this.$el);
    this.term.loadAddon(fitAddon);
    this.term.setOption("theme", { background: "#1c1c1c", foreground: "#aaa" });

    fitAddon.fit();

    this.resizer = setInterval(() => {
      fitAddon.fit();
    }, 200);
  },
  methods: {
    write() {
      this.term.write(...arguments);
    },
  },
  beforeDestroy() {
    clearInterval(this.resizer);
    this.term.dispose();
  },
};
</script>
<style>
@import url("xterm/css/xterm.css");

.term-log {
  flex: 1;
  width: 100%;
  height: 50px;
  overflow: hidden;
}

.term-log .terminal {
  height: 100%;
}
.term-log .xterm-screen {
  margin: 0 !important;
}
</style>
