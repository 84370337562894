var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-bar" }, [
    _vm._v("\n  gorge playground:\n  "),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.$emit("run")
          },
        },
      },
      [_vm._v("Run")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.$emit("share")
          },
        },
      },
      [_vm._v("Share")]
    ),
    _vm._v(" "),
    _c("label", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.vim,
            expression: "vim",
          },
        ],
        staticClass: "top-bar__vimmode",
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.vim)
            ? _vm._i(_vm.vim, null) > -1
            : _vm.vim,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.vim,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.vim = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.vim = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.vim = $$c
            }
          },
        },
      }),
      _vm._v("vimmode\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }