var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor", class: { vim: _vm.vimmode } }, [
    _c("div", { ref: "editor", staticClass: "editor__thing" }),
    _vm._v(" "),
    _c("div", { ref: "status", staticClass: "editor__vimstatus" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }