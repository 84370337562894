<template>
  <div id="app">
    <TopBar
      @run="build"
      @share="share"
      :vimmode="vimmode"
      @vimmode="updateVimMode"
    />
    <div class="container">
      <Layout :resize="true" :edit="true" :splits="tree">
        <Pane title="editor">
          <Editor
            ref="editor"
            :codeModel="codeModel"
            theme="vs-dark"
            :vimmode="vimmode"
            @save="save"
          />
        </Pane>
        <Pane title="output">
          <Output :code="compiled" />
        </Pane>
        <Pane title="log">
          <TermLog ref="log" />
        </Pane>
      </Layout>
    </div>
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
import { Layout, Pane } from "vue-split-layout";
import Editor from "./components/Editor.vue";
import TermLog from "./components/TermLog.vue";
import TopBar from "./components/TopBar.vue";
import Output from "./components/Output.vue";
import * as service from "./services/service";

export default {
  name: "App",
  components: {
    TopBar,
    Editor,
    Layout,
    Pane,
    TermLog,
    Output,
  },
  mounted() {
    window.addEventListener("message", (e) => {
      switch (e.data.type) {
        case "output":
          this.log(e.data.msg);
      }
    });
    document.getElementById("loader").classList.toggle("done");
    this.log("hello world");

    const id = window.location.pathname.substring(1);
    if (id.length > 0) {
      this.open(id);
      return;
    }
    let code = localStorage.getItem("code");
    if (code) {
      this.code = code;
      return;
    }
    openCode("default");
  },
  computed: {
    code: {
      set(value) {
        const ed = this.$refs.editor?.editor;
        const position = ed?.getPosition();
        this.codeModel.setValue(value);
        position && ed.setPosition(position);
      },
      get() {
        return this.codeModel.getValue();
      },
    },
  },
  methods: {
    log(...args) {
      const v = args.join(" ");
      const msg = v.replaceAll(/\r?\n/g, "\r\n") + "\r\n";
      this.$refs.log.write(msg);
    },
    async save() {
      const code = this.code;
      localStorage.setItem("code", code);
      try {
        this.log("formatting code...");
        const formatted = await service.format(code);
        this.code = formatted;
        localStorage.setItem("code", formatted);
        this.build();
      } catch (e) {
        this.log(e.message);
      }
    },
    async build() {
      this.log("compiling code...");
      const progress = (m) => {
        const cur = (m.cur / 1024 / 1024).toFixed(2);
        const total = (m.total / 1024 / 1024).toFixed(2);
        const perc = ((m.cur / m.total) * 100).toFixed(2);
        this.log(`Downloading wasm ${cur}Mb/${total}Mb ${perc}%`);
      };
      try {
        this.compiled = await service.build(this.code, progress);
        this.log("done");
      } catch (e) {
        this.log(e.message);
      }
    },
    async open(id) {
      try {
        this.code = await service.open(id);
      } catch (e) {
        this.log(e.message);
      }
    },
    async share() {
      try {
        const id = await service.share(this.code);
        window.history.pushState(id, "", "/" + id);
      } catch (e) {
        this.log(e.message);
      }
    },

    updateVimMode(v) {
      console.log("Setting vim mode to " + v);
      this.vimmode = v;
    },
  },
  data() {
    // Model in vuex?
    return {
      codeModel: monaco.editor.createModel(
        [
          "package main",
          "",
          "",
          "import (",
          `\t"github.com/stdiopt/gorge"`,
          `\t"github.com/stdiopt/gorge/gorgeapp"`,
          `\t"github.com/stdiopt/gorge/gorgeutil"`,
          `\t"github.com/stdiopt/gorge/primitive"`,
          ")",
          "",
          "func main() {",
          "\tgorgeapp.New(func(g *gorge.Context) {",
          "\t\t// add default cam/gimbals/lightsetc",
          "\t\tgorgeutil.AddBasic(g)",
          "\t\tcube1 := gorgeutil.NewCube()",
          "\t\tplane := gorgeutil.NewPlane(primitive.PlaneDirY)",
          "\t\tplane.SetPosition(0,-1,0)",
          "\t\tplane.SetScale(2)",
          "\t\tg.Add(cube1, plane)",
          "\t}).Run()",
          "}",
        ].join("\n"),
        "go",
        monaco.Uri.parse("file:///app/code/skel/main.go")
        //monaco.Uri.parse(
        // "file:///home/stdio/coding/Projects/gorge-dev/gorge-playground/code/skel"
        //)
      ),
      compiled: "",
      vimmode: true,
      tree: {
        dir: "vertical",
        split: "70%",
        first: {
          dir: "horizontal",
          first: 0,
          second: 1,
        },
        second: 2,
      },
    };
  },
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-flow: column;
  background: #111;
}
#loader {
  position: absolute;
}
#app {
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 1;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #777;
}

#app .top-bar {
  flex-basis: 0;
}
#app .container {
  flex: 1;
}

.views {
  margin: 3px;
}
.pane {
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.2) !important;
}
.pane .header {
  background: #252525;
  color: #667;
  padding: 3px !important;
  height: 20px !important;
  font-size: 0.8em;
}
.pane .content {
  top: 20px !important;
  background: #1e1e1e;
  padding: 0;
}
.split > .splitter {
  flex-basis: 3px;
}
.split > .splitter:hover {
  flex-basis: 8px;
}
.split.resizeable > .splitter::after {
  position: absolute;
  content: " ";
  z-index: 10;
  transition: all 0.3s;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}
</style>
