// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-2[0].rules[0].use[2]!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.term-log {\n  flex: 1;\n  width: 100%;\n  height: 50px;\n  overflow: hidden;\n}\n.term-log .terminal {\n  height: 100%;\n}\n.term-log .xterm-screen {\n  margin: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/TermLog.vue"],"names":[],"mappings":";AAmCA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACA;AAEA;EACA,YAAA;AACA;AACA;EACA,oBAAA;AACA","sourcesContent":["<template>\n  <div class=\"term-log\"></div>\n</template>\n<script>\nimport { Terminal } from \"xterm\";\nimport { FitAddon } from \"xterm-addon-fit\";\n\nexport default {\n  mounted() {\n    const fitAddon = new FitAddon();\n    this.term = new Terminal();\n    this.term.open(this.$el);\n    this.term.loadAddon(fitAddon);\n    this.term.setOption(\"theme\", { background: \"#1c1c1c\", foreground: \"#aaa\" });\n\n    fitAddon.fit();\n\n    this.resizer = setInterval(() => {\n      fitAddon.fit();\n    }, 200);\n  },\n  methods: {\n    write() {\n      this.term.write(...arguments);\n    },\n  },\n  beforeDestroy() {\n    clearInterval(this.resizer);\n    this.term.dispose();\n  },\n};\n</script>\n<style>\n@import url(\"xterm/css/xterm.css\");\n\n.term-log {\n  flex: 1;\n  width: 100%;\n  height: 50px;\n  overflow: hidden;\n}\n\n.term-log .terminal {\n  height: 100%;\n}\n.term-log .xterm-screen {\n  margin: 0 !important;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
